import {
    Container,
    Divider,
    Flex,
    Grid,
    GridItem,
    Image,
    Link,
    LinkBox,
    Text,
    VStack,
} from "@chakra-ui/react";
import Card from "components/Card";
import text from "constants/applicationText";
import { themeMockBank } from "mock";
import { NextPage } from "next";
import { BsFileEarmark, BsFileEarmarkText } from "react-icons/bs";
import { indexStyle } from "styles/index.style";

const Home: NextPage = () => {
    return (
        <Container {...indexStyle.container}>
            <Image
                m={2}
                src={text.images.imageOpenInsurance}
                alt={themeMockBank.alt}
            />
            <Text fontSize={"sm"}>{text.pages.index.selectService}</Text>
            <Flex justify={"space-between"} mt={10} w={"100%"}>
                <Card
                    title={text.pages.index.newConsent}
                    icon={<BsFileEarmark />}
                    redirectUrl="/services/consent/transmitter/home"
                />
                <Card
                    title={text.pages.index.myConsents}
                    icon={<BsFileEarmarkText />}
                    redirectUrl="/services/management/listconsent"
                />
            </Flex>
            <VStack spacing={6}>
                <Divider borderColor={"blackAlpha.600"} />
                <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={4}
                    textAlign="center"
                >
                    <GridItem
                        boxShadow="md"
                        rounded="md"
                        paddingLeft={1}
                        paddingRight={1}
                    >
                        <Link href="https://opinbrasil.com.br/cidadao/conheca-o-open-insurance">
                            {text.pages.index.whatIsOpenInsurance}
                        </Link>
                    </GridItem>
                    <GridItem boxShadow="md" rounded="md">
                        <Link href="https://opinbrasil.com.br/termos-de-uso/">
                            {text.pages.index.terms}
                        </Link>
                    </GridItem>
                </Grid>
                <LinkBox
                    fontSize={"sm"}
                    fontWeight={"bold"}
                    color={"primary.400"}
                >
                    <Link
                        _hover={{
                            textDecoration: "none",
                        }}
                        href={
                            "https://opinbrasil.com.br/cidadao/quem-participa"
                        }
                    >
                        {text.pages.index.portal}
                    </Link>
                </LinkBox>
            </VStack>
        </Container>
    );
};
export default Home;
