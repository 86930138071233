import { Box, Link as LinkChakra } from "@chakra-ui/react";

type ICard = {
    title: string;
    icon: any;
    redirectUrl: any; //!entender como refatorar esses any's
};
const Card = ({ title, icon, redirectUrl }: ICard) => {
    return (
        <LinkChakra
            fontSize={"md"}
            fontWeight={"bold"}
            bg={"gray.50"}
            borderRadius={"2xl"}
            flexWrap={"wrap"}
            w={{ sm: "xs", md: "sm" }}
            p={[2, 8]}
            m={2}
            textAlign={"center"}
            border={"2px"}
            borderColor={"gray.50"}
            href={redirectUrl}
            _hover={{
                color: "primary.500",
                shadow: "primary.500",
                border: "2px",
            }}
        >
            <Box
                display="grid"
                justifyContent="center"
                alignItems="center"
                fontSize="4rem"
                m={2}
            >
                {icon}
            </Box>
            {title}
        </LinkChakra>
    );
};
export default Card;
